import React, { useEffect } from "react"

const KlaviyoForm = () => {
    useEffect(() => {
        // Function to load and initialize the Klaviyo form
        const loadKlaviyoForm = () => {
            // Check if the Klaviyo script has already been loaded
            if (typeof Klaviyo !== "undefined") {
                // If it's already loaded, reinitialize it
                Klaviyo.reload()
            } else {
                // Otherwise, load the Klaviyo script dynamically
                const klaviyoScript = document.createElement("script")
                klaviyoScript.async = true
                klaviyoScript.type = "text/javascript"
                klaviyoScript.src =
                    "https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=WVsBSF"
                document.head.appendChild(klaviyoScript)
            }

            // Render the Klaviyo form
            const klaviyoFormContainer = document.querySelector(
                ".klaviyo-form-VyKfKe"
            )
            Klaviyo.subscribeForm({
                container: klaviyoFormContainer,
                // Add other form options here
            })
        }

        // Call the function to load and initialize the Klaviyo form
        loadKlaviyoForm()
    }, [])

    return (
        <div>
            <div className="klaviyo-form-VyKfKe"></div>
        </div>
    )
}

export default KlaviyoForm
